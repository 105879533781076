<template>
    <Table
        ref="awardsTable"
        :class="tableClass"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :toolbox="['columnSetting', 'excel', 'zooming']"
        :isFocusRow="true"
        :isLoading="isLoading"
        :sortDefault="sortDefault"
        :columnsMode="columnsMode"
        :excelConfig="excelConfig"
        :isFixedHeader="true"
        :isPagination="true"
        :descriptions="descriptions"
        :hiddenColumnDefault="hiddenColumnDefault"
    >
        <template #insNo="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'TiaInsuranceDetail',
                    params: { id: row.mainCode, manCode: row.manCode }
                }"
                >{{ row.insNo }}</router-link
            >
        </template>
        <template #payer="{ isHeader, row }">
            <template v-if="!isHeader && !row.className">
                <router-link
                    class="customer"
                    :class="{ 'no-data': !row.payer }"
                    :to="{
                        name: 'TiaCustomer',
                        query: {
                            customerCode: row.payerCode,
                            customerName: row.payer
                        }
                    }"
                >
                    {{ row.payer ?? '-' }}
                </router-link>
            </template>
        </template>
        <template #insured="{ isHeader, row }">
            <template v-if="!isHeader && !row.className">
                <router-link
                    class="customer"
                    :class="{ 'no-data': !row.insured }"
                    :to="{
                        name: 'TiaCustomer',
                        query: {
                            customerCode: row.insuredCode,
                            customerName: row.insured
                        }
                    }"
                >
                    {{ row.insured ?? '-' }}
                </router-link>
            </template>
        </template>
        <template #fynPerMonth="{ isHeader, row }">
            <template v-if="!isHeader">
                <div
                    class="detail"
                    :class="{ disabled: !row.details }"
                    @click="openDetail(row.details)"
                >
                    查看明細
                </div>
            </template>
        </template>
        <template #performanceTotal="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'performanceTotal' }
                }"
                target="_blank"
                >{{ row.performanceTotal }}</router-link
            >
        </template>
        <template #hga="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'hga' }
                }"
                target="_blank"
                >{{ row.hga }}</router-link
            >
        </template>
        <template #lga="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'lga' }
                }"
                target="_blank"
                >{{ row.lga }}</router-link
            >
        </template>
        <template #promotionOne="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'promotionOne' }
                }"
                target="_blank"
                >{{ row.promotionOne }}</router-link
            >
        </template>
        <template #promotionManager="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'promotionManager' }
                }"
                target="_blank"
                >{{ row.promotionManager }}</router-link
            >
        </template>
        <template #promotionManagement="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'promotionManagement' }
                }"
                target="_blank"
                >{{ row.promotionManagement }}</router-link
            >
        </template>
        <template #promotionOperate="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'promotionOperate' }
                }"
                target="_blank"
                >{{ row.promotionOperate }}</router-link
            >
        </template>
        <template #recommendManpower="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'recommendManpower' }
                }"
                target="_blank"
                >{{ row.recommendManpower }}</router-link
            >
        </template>
        <template #yearlyManpower="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'yearlyManpower' }
                }"
                target="_blank"
                >{{ row.yearlyManpower }}</router-link
            >
        </template>
        <template #selfSell="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'selfSell' }
                }"
                target="_blank"
                >{{ row.selfSell }}</router-link
            >
        </template>
        <template #selfManager="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'selfManager' }
                }"
                target="_blank"
                >{{ row.selfManager }}</router-link
            >
        </template>
        <template #selfManagement="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'selfManagement' }
                }"
                target="_blank"
                >{{ row.selfManagement }}</router-link
            >
        </template>
        <template #selfOperate="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'selfOperate' }
                }"
                target="_blank"
                >{{ row.selfOperate }}</router-link
            >
        </template>
        <template #underlineSell="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlineSell' }
                }"
                target="_blank"
                >{{ row.underlineSell }}</router-link
            >
        </template>
        <template #underlineManager="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlineManager' }
                }"
                target="_blank"
                >{{ row.underlineManager }}</router-link
            >
        </template>
        <template #underlineManagement="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlineManagement' }
                }"
                target="_blank"
                >{{ row.underlineManagement }}</router-link
            >
        </template>
        <template #underlineOperate="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlineOperate' }
                }"
                target="_blank"
                >{{ row.underlineOperate }}</router-link
            >
        </template>
        <template #underlinePromotionManager="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlinePromotionManager' }
                }"
                target="_blank"
                >{{ row.underlinePromotionManager }}</router-link
            >
        </template>
        <template #underlinePromotionManagement="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlinePromotionManagement' }
                }"
                target="_blank"
                >{{ row.underlinePromotionManagement }}</router-link
            >
        </template>
        <template #underlinePromotionOperate="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlinePromotionOperate' }
                }"
                target="_blank"
                >{{ row.underlinePromotionOperate }}</router-link
            >
        </template>
        <template #underlinePerPromotion="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlinePerPromotion' }
                }"
                target="_blank"
                >{{ row.underlinePerPromotion }}</router-link
            >
        </template>
        <template #yearlyManpowerCmDivideCa="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'yearlyManpowerCmDivideCa' }
                }"
                target="_blank"
                >{{ row.yearlyManpowerCmDivideCa }}</router-link
            >
        </template>
        <template #underlinePromotionOne="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlinePromotionOne' }
                }"
                target="_blank"
                >{{ row.underlinePromotionOne }}</router-link
            >
        </template>
        <template #underlineCaPromotionManager="{ isHeader, row }">
            <router-link
                v-if="!isHeader && !row.className"
                :to="{
                    name: 'AwardDetail',
                    params: { rule: contestRule },
                    query: { filter: 'underlineCaPromotionManager' }
                }"
                target="_blank"
                >{{ row.underlineCaPromotionManager }}</router-link
            >
        </template>
    </Table>
</template>
<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    awardsColumn,
    awardsColumnConfig,
    awardsSortDefault,
    awardsMode,
    awardDescription,
    awardSetting
} from '@/assets/javascripts/award/setting'
export default {
    name: 'AwardsTable',
    components: { Table },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        contestNow: {
            type: String,
            default: ''
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        },
        mode: {
            type: String,
            default: ''
        }
    },
    methods: {
        transformData(rows) {
            return rows
                .map((row) => {
                    row.level = row.rank || '尚無等級'
                    row.commitDate = row.commitPeriod || '-'
                    row.effectDate = row.effectiveDate || '-'
                    row.insuredCount = row.insuredCount || 0
                    row.insuredCompute = row.insuredCount || 0
                    row.originInsAmount = row.oriBenefits || 0
                    row.contestInsAmount = row.benefits || 0
                    row.originFyp = row.oriFyp || 0
                    row.contestFyp = row.fyp || 0
                    row.originFyb = row.oriFyb || 0
                    row.contestFyb = row.fyb || 0
                    row.contestFybTotal = row.fyb || 0
                    row.firstCommitDate = row.firstCommitPeriod || '-'
                    if (row.isVoid === true) {
                        row.status = '註銷'
                    } else if (row.isFirst === true) {
                        row.status = '正常件'
                    } else if (row.isFirst === false) {
                        row.status = '首年續次'
                    }
                    return row
                })
                .sort((a, b) => {
                    const sort = this.sortDefault?.sort
                    const sortBy = this.sortDefault?.sortBy
                    const isDate = this.sortDefault?.sortBy
                        ?.toLowerCase()
                        .includes('date')
                    if (sort === 'desc') {
                        return isDate
                            ? new Date(b[sortBy]) - new Date(a[sortBy])
                            : b[sortBy] - a[sortBy]
                    } else {
                        return isDate
                            ? new Date(a[sortBy]) - new Date(b[sortBy])
                            : a[sortBy] - b[sortBy]
                    }
                })
                .map((row, index) => {
                    row.serialNumber = index + 1
                    return row
                })
        },
        openDetail(val) {
            if (!val) return
            this.$emit('open-modal', val, false)
        }
    },
    computed: {
        tableColumnConfig() {
            if (!this.contestNow) return awardsColumn
            let config = awardsColumn
            let pickColumn = []
            // pick column by contest
            if (this.contestNow !== 'group') {
                pickColumn = awardsColumnConfig[this.contestNow]
            } else {
                switch (this.filterLog.contestRule?.value) {
                    case 'marketing':
                        pickColumn = awardsColumnConfig[this.contestNow].sell
                        break
                    case 'manager':
                        pickColumn = awardsColumnConfig[this.contestNow].manager
                        break
                    case 'management':
                        pickColumn = awardsColumnConfig[this.contestNow].operate
                        break
                }
            }
            if (this.isSelf) {
                pickColumn = pickColumn?.mode
                    ? this.mode
                        ? pickColumn.self[this.mode]
                        : pickColumn.self[_.keys(pickColumn.self)[0]]
                    : pickColumn?.self
            } else {
                pickColumn = pickColumn?.org
            }
            config = _.pick(awardsColumn, pickColumn)
            // custom column setting
            config.serialNumber.label = this.isSelf ? '編號' : '排名'

            // check should have sum row or not
            const hasSumRow = Object.keys(config).some(
                (key) => config[key].total === 'sum'
            )
            if (!hasSumRow) {
                delete config.serialNumber.total
            } else {
                config.serialNumber.total = 'key'
            }

            return config
        },
        tableShowData() {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        isSelf() {
            return this.filterLog.relationMode?.value === 'self'
        },
        columnsMode() {
            return this.isSelf ? 'self' : 'org'
        },
        sortDefault() {
            const award = this.contestNow
            let sort = this.isSelf
                ? awardsSortDefault[award]?.self
                : awardsSortDefault[award]?.org
            if (this.isSelf && awardsSortDefault[award]?.mode) {
                sort = this.mode
                    ? awardsSortDefault[award]?.self[this.mode]
                    : awardsSortDefault[award]?.self[awardsMode[award][0].value]
            }
            return sort
        },
        excelConfig() {
            const name = this.filterLog.manCode?.label || ''
            const contestName = this.filterLog.contestId?.label || ''
            const relationMode = this.filterLog.relationMode?.label || ''
            const year = this.filterLog.year?.label || ''
            const fileName = `獎項查詢_${
                year + contestName
            }_${name}_${relationMode}`
            const filters = this.filterLog

            const infos = this.infos.map((info) => {
                if ('value' in info === false) {
                    info.value = ' '
                }
                return info
            })
            return {
                fileName,
                filters,
                infos: infos,
                descriptions: this.descriptions
            }
        },
        tableClass() {
            const classList = []
            if (!this.isSelf) classList.push('org-table', 'no-sum')
            if (this.tableShowData.length <= 1) classList.push('no-sum')
            return classList.join(' ')
        },
        contestRule() {
            switch (this.filterLog.contestRule?.value) {
                case 'marketing':
                    return 'sell'
                case 'manager':
                    return 'manager'
                case 'management':
                    return 'operate'
                default:
                    return ''
            }
        },
        hiddenColumnDefault() {
            const hiddenColumn = []
            if (this.isSelf) {
                switch (this.contestNow) {
                    case 'mdrt':
                        hiddenColumn.push('originFyb')
                        break
                }
            } else {
                hiddenColumn.push('orgBmGroupName', 'orgBmName')
            }
            return hiddenColumn
        },
        descriptions() {
            const descriptions = _.cloneDeep(awardDescription)
            const contestDescriptions =
                awardSetting[this.filterLog?.contestId?.value]?.descriptions
            if (contestDescriptions) descriptions.push(...contestDescriptions)
            return descriptions
        }
    }
}
</script>
<style scoped lang="scss">
.table-wrapper {
    &.no-sum {
        :deep(.sum-row) {
            display: none !important;
        }
    }
    :deep(.row-container) {
        a {
            color: $fourth-blue !important;
            cursor: pointer;
            &.customer {
                text-decoration: underline;
            }
        }
    }
    :deep(.table-container) {
        .mobile-header {
            font-size: 12px;
            color: $secondary-grey;
            display: flex;
            flex-direction: column;
            p {
                margin: 0;
            }
        }

        .fyn-per-month {
            .detail {
                color: $fourth-blue;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 576px) {
    .table-wrapper {
        :deep(.table-container.card) {
            .sum-row {
                .serial-number {
                    justify-content: start;
                    &:before {
                        display: none;
                    }
                    &:after {
                        content: '.';
                    }
                }

                .sales-name,
                .bg-name,
                .org-bm-group-name,
                .org-bm-name,
                .relative-depth,
                .level,
                .supplier,
                .ins-no,
                .receive-date,
                .effect-date,
                .commit-date,
                .insured,
                .payer,
                .product-name,
                .main-product-name,
                .status,
                .first-commit-date {
                    display: none;
                }
            }

            .row {
                .insured-compute {
                    position: relative;
                    padding-right: 20px;
                    &:after {
                        content: '人';
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }

        &.no-self-sum:not(.org-table) {
            :deep(.sum-row) {
                display: none;
            }
        }

        &.org-table {
            :deep(.row) {
                &:not(.sum-row) {
                    .serial-number {
                        justify-content: start;
                        &:before {
                            display: block !important;
                            content: '第';
                            margin: 0;
                            color: inherit;
                        }
                        &:after {
                            content: '名' !important;
                        }
                    }
                }
            }
        }

        :deep(.row-container .row:not(.sum-row)) {
            .serial-number {
                justify-content: start;
                &:before {
                    display: none;
                }
                &:after {
                    content: '.';
                }
            }

            .product-name {
                flex-wrap: nowrap;
                text-align: end;
                gap: 1rem;
            }

            &.loading {
                .serial-number {
                    display: none;
                }
            }
        }

        :deep(.tool-option) {
            &.full-size {
                display: flex;
                @media screen and (max-width: 576px) {
                    display: none !important;
                }
            }
        }
    }
}
</style>
